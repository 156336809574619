<template>
  <div class="details_info" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="订单详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 订单顶部信息 -->
    <div class="details_payStatus" v-if="order_status == 0">
      <div>等待买家付款</div>
      <div>剩23小时8分自动关闭</div>
    </div>
    <div class="details_payStatus" v-else-if="order_status == 1">
      <div>
        {{
          detail.Status == 0
            ? "待付款"
            : detail.Status == 1
            ? "待发货"
            : detail.Status == 2
            ? "待收货"
            : detail.Status == 3
            ? "交易成功"
            : detail.Status == 4
            ? "已退款"
            : "交易成功"
        }}
      </div>
    </div>
    <div class="details_payStatus" v-else>
      <div>待收货</div>
      <div>还剩6天18时自动确认</div>
    </div>
    <!-- 用户收货地址 -->
    <div class="details_address">
      <div class="address_icon">
        <!-- <img
          src="../../../assets/ShopImg/deizhi.png"
          alt=""
          style="width: 100%; height: 100%"
        /> -->
        <van-icon name="location" size="18" color="#ffffff" />
      </div>
      <div class="address_text">
        <div v-if="detail.PickUpMethod == 0">
          <span>{{ detail.ContactName }}</span
          ><span>{{ detail.ContactPhone }}</span>
        </div>
        <div>{{ detail.Address }}</div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="order_info">
      <div
        class="info_detail"
        v-for="(goods, j) in detail.OrderDetailList"
        :key="j"
      >
        <img
          :src="goods.SpecImg"
          alt=""
          @click="toGoodsDetail(goods.GoodsId)"
        />
        <div class="detail_content">
          <div @click="toGoodsDetail(goods.GoodsId)">
            {{
              goods.GoodsName.length > 25
                ? goods.GoodsName.substr(0, 25) + "..."
                : goods.GoodsName
            }}
          </div>
          <!-- <div><span>{{goods.SpecName}}</span></div> -->
          <div>
            <span>{{ goods.SpecName }}</span
            ><span>x{{ goods.GoodsCount }}</span>
          </div>
          <div>
            <span v-if="goods.Points > 0">{{ goods.Points }}积分+</span> ￥<span
              >{{ goods.GoodsPrice }}</span
            >
            <div
              class="info_evaluate"
              @click="gotoEvaluate(goods.SpecId)"
              v-if="goods.IsEvaluation != 1 && goods.Status == 3"
            >
              <div class="pj">评价</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info_freight">
        <div>
          <span>运费</span
          ><span>运费（{{ detail.PickUpMethod == 0 ? "快递" : "自提" }}）</span>
        </div>
        <div>
          <span>￥</span><span>{{ detail.Postage }}</span>
        </div>
      </div>
      <div class="info_freight">
        <div><span>优惠</span><span>优惠券</span></div>
        <div>
          <span>-￥</span><span>{{ detail.DiscountMoney }}</span>
        </div>
      </div>
      <div
        class="info_refund"
        v-if="detail.Status == 1 || detail.Status == 2 || detail.Status == 3"
        @click="gotoRefund"
      >
        <div>退款</div>
      </div>

      <div class="info_price">
        <div>{{ detail.Status == 0 ? "需付款：" : "实付款：" }}</div>
        <div>
          <span>￥</span><span>{{ detail.TotalMoney }}</span>
        </div>
      </div>
      <!-- 数量 -->
      <!-- <div class="info_num"><van-stepper v-model="value" /></div> -->
    </div>
    <!-- 积分 -->
    <div class="details_integral">
      <div>积分</div>
      <div>
        获得<span>{{ detail.GivePoint }}</span
        >点积分
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="details_order">
      <div>订单信息</div>
      <!-- <div v-if="order_status != 2">
        <span>物流编号: 689595968796879874</span><span>复制</span>
      </div> -->
      <div>
        <span>订单编号: {{ detail.ShopOrderNumber }}</span>
      </div>
      <div v-if="order_status == 0"><span>积分: 获得0点积分</span></div>
      <div>
        <span>创建时间: {{ detail.CreateTime }}</span>
      </div>
      <div>
        <span>支付时间: {{ detail.PayTime }}</span>
      </div>
    </div>
    <div style="height: 3.375rem"></div>
    <!-- 底部按钮 -->
    <div class="details_btm_btn">
      <div class="details_icon">
        <van-icon
          name="service-o"
          size="24"
          color="#333333"
          @click="goService"
        />
        <!-- <van-goods-action-icon icon="service-o" text="客服" @click="goService" /> -->
      </div>
      <div class="details_btn">
        <div v-if="detail.Status == 0" @click="deleteOrder">取消订单</div>
        <!-- <div v-else-if="detail.Status == 1">提醒发货</div> -->
        <div
          v-if="detail.Status == 2 || detail.Status == 3"
          @click="gotoLogistics"
        >
          查看物流
        </div>
        <div
          @click="pay(detail)"
          v-if="detail.Status == 0 || detail.Status == 2"
        >
          {{ detail.Status == 0 ? "付款" : "确认收货" }}
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="支付方式"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  queryShopOrderDetail,
  shopOrderDelete,
  shopOrderPay,
} from "@/api/shop";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      value: 1,
      orderId: 0,
      detail: {},
      order_status: 1, // 订单状态 暂定 0 未支付，1 待发货，2 待收货
      payOrderId: 0,
      showPicker: false,
      columns: ["普利点", "支付宝", "微信"],
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "订单详情";
      this.isWxApplets = false;
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.getQueryShopOrderDetail();
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    goService() {
      console.log(1);
      // this.$router.push({
      //   path: "/shop/service/index",
      //   query: {},
      // });
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc8db1b9998eef1aba";
    },
    // 付款
    pay(itm) {
      if (itm.Status == 0) {
        // console.log("付款");
        this.showPicker = true;
        this.payOrderId = itm.Id;
      } else {
        this.$router.push({
          path: "/shop/orders/logistics",
          query: { orderId: itm.Id },
        });
      }
    },
    async onConfirm(value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      let parm = {
        OrderId: this.payOrderId,
        PayMethod: index,
        OrderSource: "H5",
      };
      const res = await shopOrderPay(parm);
      this.showPicker = false;
      if (res.success == true) {
        if (index == 0) {
          //普利点支付
          Toast.success({
            message: res.msg,
          });
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
          this.getQueryShopOrderDetail();
        } else if (index == 1) {
          //支付宝支付
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.response.PayData;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        } else if (index == 2) {
          //微信支付
          window.open(res.response.PayData);
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        }
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    onCancel() {
      Toast("取消支付");
      this.showPicker = false;
    },
    // 订单详情
    async getQueryShopOrderDetail() {
      const res = await queryShopOrderDetail(this.orderId);
      this.detail = res.response;
    },
    //取消订单
    deleteOrder() {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消该订单吗",
      })
        .then(() => {
          this.postShopOrderDelete();
        })
        .catch(() => {
          // on cancel
        });
    },
    //取消订单
    async postShopOrderDelete() {
      const res = await shopOrderDelete(this.orderId);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        // this.$router.push({
        //   path: "/shop/orders/index",
        // });
        this.$router.go(-1);
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    //查看物流
    gotoLogistics() {
      this.$router.push({
        path: "/shop/orders/logistics",
        query: { orderId: this.orderId },
      });
    },
    //前去退款
    gotoRefund() {
      this.$router.push({
        path: "/shop/orders/refund",
        query: { orderId: this.orderId },
      });
    },
    gotoEvaluate(specId) {
      this.$router.push({
        path: "/shop/orders/evaluate",
        query: { orderId: this.orderId, specId: specId },
      });
    },
    toGoodsDetail(goodsId) {
      this.$router.push({
        path: "/shop/goods/detail/?id=" + goodsId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.details_info {
  position: relative;
  .details_payStatus {
    width: 100%;
    height: 6.5rem;
    background: linear-gradient(to right, #fc6d33, #f54124);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.0625rem;
    color: #fff;
    div:first-child {
      margin-top: 0.9375rem;
    }
    div:nth-child(2) {
      margin-top: 0.3125rem;
      font-size: 0.8125rem;
      color: #fa947b;
    }
  }
  .details_address {
    width: 21.5625rem;
    min-height: 5.25rem;
    background: #fff;
    // position: absolute;
    // left: 0.9375rem;
    // top: 7.8125rem;
    border-radius: 0.625rem;
    padding: 1rem 0.625rem 1rem 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -2.8125rem auto 0;
    .address_icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      flex: 0 0 2rem;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      border-radius: 50%;
      margin-right: 10px;
    }
    .address_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.8125rem;
      color: #999999;
      width: 100%;
      height: 80%;
      div:first-child {
        span:first-child {
          font-size: 0.9375rem;
          color: #333;
          font-weight: 700;
          margin-right: 0.5rem;
        }
      }
    }
    .address_edit {
      font-size: 0.875rem;
      color: #999999;
      width: 3.25rem;
      height: 1.625rem;
      line-height: 1.625rem;
      text-align: center;
      border-radius: 1.875rem;
      border: 1px solid #e8e8e8;
    }
  }
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    margin-top: 1rem;
    position: relative;
    .info_detail {
      display: flex;
      margin-bottom: 0.9375rem;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        flex: 0 0 5.5625rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
          margin-right: 1.25rem;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
        }
      }
    }
    .info_freight {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      color: #333333;
      margin-bottom: 0.5rem;
      div:first-child {
        margin-left: 3.8rem;
        span:first-child {
          margin-right: 0.625rem;
        }
        span:nth-child(2) {
          color: #d2d1d1;
        }
      }
      div:nth-child(2) {
        span:first-child {
          font-size: 0.5625rem;
        }
        // span:nth-child(2) {
        //   font-size: 1rem;
        //   font-weight: 700;
        // }
      }
    }
    .info_refund {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.75rem;
      div {
        width: 4.125rem;
        height: 1.75rem;
        border-radius: 1.875rem;
        border: 1px solid #e8e8e8;
        font-size: 0.75rem;
        color: #999;
        text-align: center;
        line-height: 1.75rem;
      }
    }
    .info_price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // font-size: 1rem;
      color: #333333;
      div:first-child {
        font-weight: 700;
      }
      div:nth-child(2) {
        color: #fe4a4a;
        span:first-child {
          font-size: 0.5625rem;
        }
        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
    .info_num {
      position: absolute;
      right: 0.9375rem;
      top: 4.8125rem;
    }
  }
  .details_integral {
    width: 100%;
    height: 3.125rem;
    padding: 0 0.9375rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin-top: 0.625rem;
    background: #fff;
    div:first-child {
      width: 2.25rem;
      height: 1.25rem;
      border: 1px solid #fe4a4a;
      font-size: 0.75rem;
      color: #fe4a4a;
      text-align: center;
      line-height: 1.25rem;
      border-radius: 1.875rem;
      margin-right: 0.5rem;
    }
    div:nth-child(2) {
      height: 3.125rem;
      line-height: 3.125rem;
      color: #333;
      span {
        color: #fe4a4a;
      }
    }
  }
  .details_order {
    margin-top: 0.625rem;
    width: 100%;
    // height: 10.625rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    background: #fff;
    font-size: 0.8125rem;
    color: #333;
    div {
      margin-bottom: 0.625rem;
    }
    div:first-child {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 0.75rem;
    }
    div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      span:nth-child(2) {
        color: #f95e2e;
      }
    }
  }
  .details_btm_btn {
    width: 100%;
    height: 3.375rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.9375rem;
    .details_icon {
      width: 1.125rem;
      height: 1.125rem;
      // border: 1px solid red;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .details_btn {
      display: flex;
      align-items: center;
      div {
        width: 5rem;
        height: 2.125rem;
        border-radius: 1.875rem;
        text-align: center;
        line-height: 2.125rem;
      }
      div:first-child {
        border: 1px solid #000000;
        color: #000000;
      }
      div:nth-child(2) {
        margin-left: 0.625rem;
        background: linear-gradient(to right, #000000, #000000);
        color: #ffffff;
      }
    }
  }
}
.info_evaluate {
  position: absolute;
  // top: 31%;
  margin-top: -25px;
  left: 83%;
  width: 3.125rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 1px solid #e8e8e8;
  color: #999;
  line-height: 1.5rem;
  .pj {
    text-align: center;
    font-weight: 400 !important;
    font-size: 12px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0 !important;
  }
}
</style>
